import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { toast } from "vue3-toastify";

dayjs.extend(duration);

const RESEND_EMAIL_TIMEOUT = 599;

const difference = (timestamp: number) =>
	dayjs(timestamp).diff(dayjs().subtract(RESEND_EMAIL_TIMEOUT, "second"), "second");

const useFunrizeEmailConfirm = (immediateRequest = false) => {
	const { data: appInit } = useAppInitData();

	const { emailVerificationTimerCookie } = useEmailVerificationTimerCookie();

	const expirationTime = Date.now() - Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT;

	const duration =
		expirationTime > 0 ? ref(difference(Number(emailVerificationTimerCookie.value))) : ref(RESEND_EMAIL_TIMEOUT);

	const { pause, resume } = useInterval(1000, {
		controls: true,
		immediate: !!emailVerificationTimerCookie.value,
		callback: () => {
			duration.value = difference(Number(emailVerificationTimerCookie.value) || Date.now());
		}
	});

	const durationLeft = computed(() => dayjs.duration(duration.value, "second").format("mm[ : ]ss"));

	watch(duration, () => {
		if (duration.value < 1) {
			pause();
		}
	});

	watch(emailVerificationTimerCookie, () => {
		duration.value = difference(Number(emailVerificationTimerCookie.value));
		resume();
	});

	const verifyLoading = ref(false);

	const email = useState("userEmail", () => "");
	const isEmailTouched = ref(false);
	const isEmailValid = computed(() => validateEmail(email.value));
	const errorMessage = ref("");
	const isEmailShowError = computed(() => (!isEmailValid.value && isEmailTouched.value) || !!errorMessage.value);

	const isEmailEntered = useState("userEmailEntered", () => false);

	const funnelType = computed(() => appInit.value?.Registration?.registrationFunnel?.funnelType);
	const isClosedFunnel = computed(() => funnelType.value === "closed_funnel");

	const activeSocialAccounts = computed(() => appInit.value?.activeSocialAccounts);
	const profileEmail = computed(() => appInit.value?.email);
	const isFullWidth = computed(() => isClosedFunnel.value || !profileEmail.value);

	const { execute: handleRequestEmail } = useAsyncFetch({
		path: "/rest/player/email/confirmation/request/",
		method: "post",
		fetchOptions: {
			onResponse: ({ response }) => {
				if (response._data.success) {
					duration.value = RESEND_EMAIL_TIMEOUT;
					emailVerificationTimerCookie.value = Date.now().toString();
					resume();
					return;
				}

				if (response._data.message) {
					toast.success(response._data.message, {
						containerId: "email-confirm-container",
						theme: toast.THEME.DARK,
						position: toast.POSITION.BOTTOM_CENTER,
						transition: toast.TRANSITIONS.SLIDE,
						autoClose: 3000
					});
				}
			}
		},
		options: {
			immediate: Boolean(immediateRequest && profileEmail.value && duration.value < 0),
			lazy: true
		}
	});

	const { execute: handleSendEmailClick } = useAsyncFetch({
		path: "/rest/player/email/change/",
		method: "post",
		fetchOptions: () => ({
			onRequest: () => {
				verifyLoading.value = true;
			},
			onResponse: ({ response }) => {
				verifyLoading.value = false;

				if (response._data.success === true) {
					dispatchGAEvent({
						event: "verification",
						location: "rewards",
						step: "info",
						type: "email"
					});
					isEmailEntered.value = true;
					handleRequestEmail();
					return;
				}
				errorMessage.value =
					response._data.errors.message || response._data.errors.type || response._data.errors.email[0];
			},
			onRequestError: () => {
				verifyLoading.value = false;
			},
			onResponseError: () => {
				verifyLoading.value = false;
			},
			body: {
				email: email.value
			}
		}),
		options: {
			immediate: false,
			lazy: true
		}
	});

	return {
		duration,
		durationLeft,
		email,
		activeSocialAccounts,
		isEmailTouched,
		isEmailShowError,
		isEmailValid,
		isEmailEntered,
		verifyLoading,
		errorMessage,
		profileEmail,
		isClosedFunnel,
		isFullWidth,
		handleSendEmailClick,
		handleRequestEmail
	};
};

export default useFunrizeEmailConfirm;
